<template>
  <div>
    <b-row>
      <b-col cols="2">
        <b-card
          v-if="data"
          body-class="pb-50"
        >
          <h6>Cảnh báo</h6>
          <h2 class="font-weight-bolder mb-1">
            3
          </h2>
          <!-- chart -->
          <vue-apex-charts
            height="70"
            :options="statisticsOrder.chartOptions"
            :series="data.statisticsOrder.series"
          />
        </b-card>
      </b-col>
      <b-col cols="2">
        <b-card
          v-if="data"
          class="card-tiny-line-stats"
          body-class="pb-50"
        >
          <h6>Phiếu sự cố</h6>
          <h2 class="font-weight-bolder mb-1">
            3
          </h2>
          <!-- chart -->
          <vue-apex-charts
            height="70"
            :options="statisticsProfit.chartOptions"
            :series="data.statisticsProfit.series"
          />
        </b-card>
      </b-col>
      <b-col cols="8">
        <b-card
          v-if="data"
          no-body
          class="card-statistics"
        >
          <b-card-header>
            <b-card-title>Thống kế</b-card-title>
            <b-card-text class="font-small-2 mr-25 mb-0">
              Số liệu 30 ngày gần đây
            </b-card-text>
          </b-card-header>
          <b-card-body class="statistics-body">
            <b-row>
              <b-col
                v-for="item in data.statisticsItems"
                :key="item.icon"
                xl="3"
                sm="6"
                :class="item.customClass"
              >
                <b-media no-body>
                  <b-media-aside

                    class="mr-2"
                  >
                    <b-avatar
                      size="48"
                      :variant="item.color"
                    >
                      <feather-icon
                        size="24"
                        :icon="item.icon"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ item.title }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      {{ item.subtitle }}
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    
  </div>
</template>
<script src="https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.4/lodash.js"></script>
<script>
import { BMedia,BTable, BMediaAside, BAvatar,BCardBody,BCardTitle,BCardHeader, BMediaBody,BRow, BCol,BCard, BTab, BCardText,BTabs } from "bootstrap-vue";
import VuePdfApp from 'vue-pdf-app'
import 'vue-pdf-app/dist/icons/main.css'
import useJwt from "@/auth/jwt/useJwt";
import Select2 from 'v-select2-component'
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue';
import VueApexCharts from 'vue-apexcharts'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4plugins_forceDirected from "@amcharts/amcharts4/plugins/forceDirected";
import hanoiMap from '@core/assets/hanoi.geo.json'
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_animated);
import numeral from 'numeral';
import Vue from 'vue';
import numFormat from 'vue-filter-number-format';
Vue.filter('numFormat', numFormat(numeral));
import moment from 'moment'
import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBgZ1_U_7ju6PC-4aYULJN3Mexa6kkiE9Q',
    libraries: 'places',
    region: 'VI',
    language: 'vi',
  },
})
import { $themeColors } from '@themeConfig'
import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
import { kFormatter } from '@core/utils/filter'

export default {
  components: {StatisticCardWithAreaChart,BTable,BMedia, BMediaAside,BCardHeader,BCardTitle,BCardBody, BAvatar, BMediaBody,VueApexCharts, BRow,BCol,VuePdfApp,Select2,BCard,flatPickr,BTab, BCardText, BTabs, FeatherIcon},
  data() {
    return {
      paths: [],
      center: {lat:20.970653, lng:105.727130},
      data: {
        statisticsItems: [
          {
            icon: 'TrendingUpIcon',
            color: 'light-primary',
            title: '140',
            subtitle: 'Cảnh báo',
            customClass: 'mb-2 mb-xl-0',
          },
          {
            icon: 'UserIcon',
            color: 'light-info',
            title: '120',
            subtitle: 'Sự cố',
            customClass: 'mb-2 mb-xl-0',
          },
          {
            icon: 'BoxIcon',
            color: 'light-danger',
            title: '4',
            subtitle: 'Offline Camera',
            customClass: 'mb-2 mb-sm-0',
          },
          {
            icon: 'DollarSignIcon',
            color: 'light-success',
            title: '30',
            subtitle: 'Online Camera',
            customClass: '',
          },
        ],
        statisticsOrder: {
          series: [
            {
              name: '2020',
              data: [45, 85, 65, 45, 65],
            },
          ],
        },
        statisticsProfit: {
          series: [
            {
              data: [0, 20, 5, 30, 15, 45],
            },
          ],
        },
        earningsChart: {
          series: [53, 16, 31],
        },
        ordersRecevied: {
          series: [
            {
              name: 'Orders',
              data: [10, 15, 8, 15, 7, 12, 8],
            },
          ],
          analyticsData: {
            orders: 38400,
          },
        },
        revenue: {
          years: ['2020', '2019', '2018'],
          price: '25,852',
          budget: '56,800',
          revenueReport: {
            series: [
              {
                name: 'Earning',
                data: [95, 177, 284, 256, 105, 63, 168, 218, 72],
              },
              {
                name: 'Expense',
                data: [-145, -80, -60, -180, -100, -60, -85, -75, -100],
              },
            ],
          },
          budgetChart: {
            series: [
              {
                data: [61, 48, 69, 52, 60, 40, 79, 60, 59, 43, 62],
              },
              {
                data: [20, 10, 30, 15, 23, 0, 25, 15, 20, 5, 27],
              },
            ],
          },
        },
        companyTable: [
          {
            avatarImg: require('@/assets/images/icons/toolbox.svg'),
            title: 'Dixons',
            subtitle: 'meguc@ruj.io',
            avatarIcon: 'MonitorIcon',
            avatarColor: 'light-primary',
            avatarTitle: 'SDF023767A',
            viewTitle: '23.4k',
            viewsub: 'in 24 hours',
            revenue: '891.2',
            sales: '68',
            loss: true,
          },
          {
            avatarImg: require('@/assets/images/icons/parachute.svg'),
            title: 'Motels',
            subtitle: 'vecav@hodzi.co.uk',
            avatarIcon: 'CoffeeIcon',
            avatarColor: 'light-success',
            avatarTitle: 'SDF0237670',
            viewTitle: '78k',
            viewsub: 'in 2 days',
            revenue: '668.51',
            sales: '97',
            loss: false,
          },
          {
            avatarImg: require('@/assets/images/icons/brush.svg'),
            title: 'Zipcar',
            subtitle: 'davcilse@is.gov',
            avatarIcon: 'WatchIcon',
            avatarColor: 'light-warning',
            avatarTitle: 'SDF0237672',
            viewTitle: '162',
            viewsub: 'in 5 days',
            revenue: '522.29',
            sales: '62',
            loss: false,
          },
          {
            avatarImg: require('@/assets/images/icons/star.svg'),
            title: 'Owning',
            subtitle: 'us@cuhil.gov',
            avatarIcon: 'MonitorIcon',
            avatarColor: 'light-primary',
            avatarTitle: 'SDF0237671',
            viewTitle: '214',
            viewsub: 'in 24 hours',
            revenue: '291.01',
            sales: '88',
            loss: false,
          },
          {
            avatarImg: require('@/assets/images/icons/book.svg'),
            title: 'Cafés',
            subtitle: 'pudais@jife.com',
            avatarIcon: 'CoffeeIcon',
            avatarColor: 'light-success',
            avatarTitle: 'SDF0237674',
            viewTitle: '208',
            viewsub: 'in 1 week',
            revenue: '783.93',
            sales: '16',
            loss: true,
          },
          {
            avatarImg: require('@/assets/images/icons/rocket.svg'),
            title: 'Kmart',
            subtitle: 'bipri@cawiw.com',
            avatarIcon: 'WatchIcon',
            avatarColor: 'light-warning',
            avatarTitle: 'SDF0237676',
            viewTitle: '990',
            viewsub: 'in 1 month',
            revenue: '780.05',
            sales: '78',
            loss: false,
          },
          {
            avatarImg: require('@/assets/images/icons/speaker.svg'),
            title: 'Payers',
            subtitle: 'luk@izug.io',
            avatarIcon: 'WatchIcon',
            avatarColor: 'light-warning',
            avatarTitle: 'SDF0237679',
            viewTitle: '12.9k',
            viewsub: 'in 12 hours',
            revenue: '531.49',
            sales: '42',
            loss: false,
          },
        ],
        meetup: {
          mediaData: [
            { avatar: 'CalendarIcon', title: 'Sat, May 25, 2020', subtitle: '10:AM to 6:PM' },
            { avatar: 'MapPinIcon', title: 'Central Park', subtitle: 'Manhattan, New york City' },
          ],
          avatars: [
            { avatar: require('@/assets/images/portrait/small/avatar-s-9.jpg'), fullName: 'Billy Hopkins' },
            { avatar: require('@/assets/images/portrait/small/avatar-s-6.jpg'), fullName: 'Amy Carson' },
            { avatar: require('@/assets/images/portrait/small/avatar-s-8.jpg'), fullName: 'Brandon Miles' },
            { avatar: require('@/assets/images/portrait/small/avatar-s-7.jpg'), fullName: 'Daisy Weber' },
            { avatar: require('@/assets/images/portrait/small/avatar-s-20.jpg'), fullName: 'Jenny Looper' },
          ],
        },
        goalOverview: {
          completed: '300',
          inProgress: '13',
          series: [96],
        },
        transactionData: [
          {
            mode: 'SDF02342341',
            types: 'Sunell',
            avatar: 'PocketIcon',
            avatarVariant: 'light-primary',
            payment: '20\'',
            deduction: true,
          },
          {
            mode: 'SDF023767',
            types: 'Add Money',
            avatar: 'CheckIcon',
            avatarVariant: 'light-success',
            payment: '60\'',
            deduction: false,
          },
          {
            mode: 'SDF02342342',
            types: 'Sunell',
            avatar: 'DollarSignIcon',
            avatarVariant: 'light-danger',
            payment: '50\'',
            deduction: false,
          },
          {
            mode: 'SDF02342344',
            types: 'Sunell',
            avatar: 'CreditCardIcon',
            avatarVariant: 'light-warning',
            payment: '40\'',
            deduction: true,
          },
          {
            mode: 'SDF02342345',
            types: 'Sunell',
            avatar: 'TrendingUpIcon',
            avatarVariant: 'light-info',
            payment: '20\'',
            deduction: false,
          },
        ],
      },
      statisticsOrder: {
        chartOptions: {
          chart: {
            type: 'bar',
            stacked: true,
            toolbar: {
              show: false,
            },
          },
          grid: {
            show: false,
            padding: {
              left: 0,
              right: 0,
              top: -15,
              bottom: -15,
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '20%',
              startingShape: 'rounded',
              colors: {
                backgroundBarColors: ['#f3f3f3','#f3f3f3','#f3f3f3','#f3f3f3','#f3f3f3'],
                backgroundBarRadius: 5,
              },
            },
          },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          colors: [$themeColors.warning],

          xaxis: {
            labels: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: {
            show: false,
          },
          tooltip: {
            x: {
              show: false,
            },
          },
        },
      },
      statisticsProfit: {
        chartOptions: {
          chart: {

            type: 'line',
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
          },
          grid: {
            borderColor: '#EBEBEB',
            strokeDashArray: 5,
            xaxis: {
              lines: {
                show: true,
              },
            },
            yaxis: {
              lines: {
                show: false,
              },
            },
            padding: {
              top: -30,
              bottom: -10,
            },
          },
          stroke: {
            width: 3,
          },
          colors: [$themeColors.info],
          markers: {
            size: 2,
            colors: $themeColors.info,
            strokeColors: $themeColors.info,
            strokeWidth: 2,
            strokeOpacity: 1,
            strokeDashArray: 0,
            fillOpacity: 1,
            discrete: [
              {
                seriesIndex: 0,
                dataPointIndex: 5,
                fillColor: '#ffffff',
                strokeColor: $themeColors.info,
                size: 5,
              },
            ],
            shape: 'circle',
            radius: 2,
            hover: {
              size: 3,
            },
          },
          xaxis: {
            labels: {
              show: true,
              style: {
                fontSize: '0px',
              },
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: {
            show: false,
          },
          tooltip: {
            x: {
              show: false,
            },
          },
        },
      },
      fields: [
        { key: 'category', label: 'Device ID' },
        { key: 'sales', label: 'Nhiệt độ' },
      ],
      goalOverviewRadialBar: {
        chart: {
          height: 245,
          type: 'radialBar',
          sparkline: {
            enabled: true,
          },
          dropShadow: {
            enabled: true,
            blur: 3,
            left: 1,
            top: 1,
            opacity: 0.1,
          },
        },
        colors: ['#51e5a8'],
        plotOptions: {
          radialBar: {
            offsetY: -10,
            startAngle: -150,
            endAngle: 150,
            hollow: {
              size: '77%',
            },
            track: {
              background: '#ebe9f1',
              strokeWidth: '50%',
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                color: '#5e5873',
                fontSize: '2.86rem',
                fontWeight: '600',
              },
            },
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'horizontal',
            shadeIntensity: 0.5,
            gradientToColors: [$themeColors.success],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: 'round',
        },
        grid: {
          padding: {
            bottom: 30,
          },
        },
      },
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,

      infoOptions: {
      content: '',
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
    };
  },
  watch:{
    
  },
  mounted () {
    this.readGeojson();
  },
  created() {
    
  },
  methods: {
    toggleInfoWindow: function() {
      this.infoWindowPos = {lat: 21.028083,lng: 105.851056};
      this.infoOptions.content = 'Camera 175B1F Vị trí đo DCL 112, cảnh báo nhiệt độ cao 40 độ';

      this.infoWinOpen = true;
    },
    readGeojson: function () {
        console.log(hanoiMap.geometries[0].coordinates[0][0])
        hanoiMap.geometries[0].coordinates[0][0].forEach(i=>{
          this.paths.push({lng:i[0],lat:i[1]})
        })
        console.log(this.paths)
      },
    kFormatter
  }
};
</script>
<style>
.pdf-app .toolbar{
  z-index: 100;
}
.select2-selection--single:before {
  content: "";
  position: absolute;
  right: 14px;
  top: 0;
  bottom: 0;
  width: 14px;
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNiIgdmlld0JveD0iMCAwIDEwIDYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDFMNSA1TDkgMSIgc3Ryb2tlPSIjMDBCMEVDIiBzdHJva2Utd2lkdGg9IjEuMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPg==") center / contain no-repeat;
}
.select2-selection__arrow{
  background: none;
  display: none !important;
}
.vgt-table td,.vgt-table th{
  padding: 0.25em 0.35em !important;
  vertical-align: middle !important;
  font-size: 1rem;
}
.vgt-table th{
  text-align: center;
}
</style>
<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
